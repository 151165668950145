import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const About = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "category.about" })} />
      <section className="w-full md:w-3/4 flex flex-col items-center px-3">
        <article className="flex flex-col border border-gray-400 mb-3 bg-white rounded">
          <div className="flex flex-col justify-start p-6">
            {/* Title */}
            <h1 className="text-3xl font-bold hover:text-gray-700 pb-6">
              {intl.formatMessage({ id: "about.title" })}
            </h1>
            {/* 記事本文 */}
            <h1 className="text-xl font-bold py-6">
              <span role="img" aria-label="memo">
                📝
              </span>
              {intl.formatMessage({ id: "about.h1" })}
            </h1>
            <p className="pb-3">
              {intl.formatMessage({ id: "about.p1" })}
              <span role="img" aria-label="smile">
                😆
              </span>
            </p>
            <p className="pb-3">
              {intl.formatMessage({ id: "about.p2" })}
              {intl.formatMessage({ id: "about.p3" })}
            </p>
            <h1 className="text-xl font-bold py-6">
              <span role="img" aria-label="pray">
                🙏🏻
              </span>
              {intl.formatMessage({ id: "about.h2" })}
            </h1>
            <p className="pb-3">
              {intl.formatMessage({ id: "about.p4" })}
              <Link to="/info/terms/" className="text-blue-700 font-bold">
                {intl.formatMessage({ id: "about.p4_1" })}
              </Link>
              {intl.formatMessage({ id: "about.p5" })}
            </p>
            <p className="pb-3">
              {intl.formatMessage({ id: "about.p6" })}
              <span className="font-bold">
                {intl.formatMessage({ id: "about.p7" })}
              </span>
              {intl.formatMessage({ id: "about.p8" })}🙇‍♂️
            </p>
            <h1 className="text-xl font-bold py-6">
              <span role="img" aria-label="pallete">
                🎨
              </span>
              {intl.formatMessage({ id: "about.h3" })}
            </h1>
            <p className="pb-3">{intl.formatMessage({ id: "about.p9" })}</p>
            <h1 className="text-xl font-bold py-6">
              <span role="img" aria-label="mager">
                📐
              </span>
              {intl.formatMessage({ id: "about.h4" })}
            </h1>
            <p className="pb-3">{intl.formatMessage({ id: "about.p10" })}</p>
            <p className="pb-3">{intl.formatMessage({ id: "about.p11" })}</p>
          </div>
        </article>
      </section>
    </Layout>
  )
}
export default About
